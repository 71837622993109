$body-font: "Open Sans", sans-serif;
$header-font: "Montserrat", sans-serif;

// Color
$med-gray: #cecece;
$light-gray: #d7f0f3;
$dark-gray: #36363c;
$slate: #47474f;
$dark-red: #b92525;
$dark-blue: #10417a;
$light-blue: #6ec1fe;
$blue: #005ef7;
$bright-blue: #0fabe6;
$sand: #d7f0f3;
$white: #fff;
$turquoise: #004c4b;
$sea-green: #0099a9;

// color palatte
$bleu-de-france: #0089ebff;
$oxford-blue: #061e34ff;
$bright-navy-blue: #0076ebff;
$green-blue: #0467bcff;
$columbia-blue: #b0cedeff;
$blue-green: #0090bbff;

// Color Assignments - CUSTOMIZE THESE
$brand-color: $green-blue;
$border-color: $med-gray;
$delete-color: $dark-red;
$dark-text: $oxford-blue;
$light-text: $white;
$accent-color: $dark-text;

$small-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$medium-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

// controls?
// $body-text: $dark-text;
// $h1-color: $brand-color;
// $h2-color: $accent-color;
// $h3-color: $brand-color;
// $background-color: $white;
// $box-shadow-cover-list: 0 0 20px rgba(0, 0, 0, 0.5);

$body-text: $dark-text;
$h1-color: $dark-text;
$h2-color: $dark-text;
$h3-color: $dark-text;
$background-color: $white;
$box-shadow-cover-list: 0 0 20px rgba(0, 0, 0, 0.5);

// $body-text: $light-gray;
// $h1-color: $light-gray;
// $h2-color: $bright-blue;
// $h3-color: $white;
// $background-color: $slate;
// $box-shadow-cover-list: 0 0 10px rgba(255, 255, 255, 0.3);
