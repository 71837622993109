// .night-mode {
//   background-color: $slate !important;
//   body {
//     color: $sand !important;
//   }
//   h1 {
//     color: $light-gray !important;
//   }
//   h2 {
//     color: $bright-blue !important;
//   }
//   h3 {
//     color: $white !important;
//   }
//   a {
//     color: $light-gray !important;
//   }
//   a:visited {
//     color: $light-gray !important;
//   }
//   button {
//     background-color: $bright-blue !important;
//   }
//   .portfolio-item {
//     box-shadow: 0 0 5px rgba(0, 0, 0, 1) !important;
//     &:hover {
//       box-shadow: 0 0 20px rgba(255, 255, 255, 0.75) !important;
//     }
//   }
//   .video {
//     box-shadow: 0 0 5px rgba(0, 0, 0, 1) !important;
//   }
//   .top-details {
//     img {
//       box-shadow: 0 0 5px rgba(0, 0, 0, 1) !important;
//     }
//   }
//   .image-container {
//     box-shadow: 0 0 px rgba(0, 0, 0, 1) !important;
//   }
// }

.home {
  // padding: 20px;
  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
    @include respond-below(md) {
      font-size: 2rem;
    }
    @include respond-below(sm) {
      font-size: 1.8rem;
    }
  }
}
.portfolio-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2vw;
  grid-row-gap: 10px;
  max-width: 100%;
  padding: 1vw;
  @include respond-below(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.portfolio-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  // border: 1px solid #ccc;
  transition-property: transform;
  transition-duration: 0.4s;
  // transition-delay: 2s;

  img {
    max-width: 100%;
    height: auto;

    &:hover {
      box-shadow: $box-shadow-cover-list;
    }
  }
  &:hover {
    border: none;
    transform: scale(1.1);
  }
}
.detail {
  // margin: 2vw;
  text-align: center;
  img {
    max-width: 40%;
    margin: 1vw;
    height: auto;
  }
  h2 {
    text-align: center;
  }
  .portfolio-container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2vw;
    margin: 0 20vw;
    .portfolio-item {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  @include respond-below(sm) {
    .portfolio-container {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 2vw;
    }
  }
}

.social-impact {
  img {
    margin: 0 !important;
  }
}

.top-details {
  padding-left: 20vw;
  width: 100vw;
  display: flex;
  flex-direction: row;
  box-shadow: 0 1em 1em -1em rgba(0, 0, 0, 0.25);
  img {
    max-width: 20%;
    margin: 1vw;
    height: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  @include respond-below(sm) {
    padding: 0;

    img {
      margin: 2vw;
      max-width: 30%;
    }
  }
}
.title-credits {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  h1 {
    margin-top: 0;
  }
  @include respond-below(sm) {
    margin: 2vw 0;
    text-align: left;
  }
}
.video {
  display: flex;

  justify-content: center;
  margin: 5vh auto;
  // padding: 10px;
  height: 390px;
  width: 640px;
  // border: inset;
  // border-radius: 3px;
  box-shadow: $box-shadow-cover-list;
  @include respond-below(sm) {
    width: 95vw;
  }
}
.vimeo-container {
  display: flex;
  margin: 5vh auto;
  justify-content: center;

  iframe {
    width: 640px;
    box-shadow: $box-shadow-cover-list;
    @include respond-below(sm) {
      width: 95vw;
    }
  }
}
