*:focus {
    outline: none;
}

.required-field {
    font-size: 1.4rem;
}

.form-errors-list {
    background-color: #c31515;
    color: white;
    padding: 10px;
}

.button-container {
    margin-top: 50px;
    width: 100%;
    text-align: center;

    button {
        border-radius: 5px;
    }
}

input {
    width: calc(100% - 10px);
    padding: 5px;
    height: 30px;
    font-size: 1.6rem;
    border: 1px solid #cecece;
    border-radius: 2px !important;
    color: black;
}

input[type="checkbox"] {
    width: 15px;
}
 
input[type="text"], input[type="password"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    padding: 5px;
    height: 50px;
    width: 100%;
    font-size: 1.6rem;
    border: 1px solid #cecece;
    border-radius: 2px !important;
    color: black;
    font-family: $body-font;
}

.checkbox-input {
    display: flex;

    label {
        margin-top: 5px;
        margin-left: 5px;
    }
}

// react-hook-form select components
.css-yk16xz-control {
    border-radius: 2px !important;
    width: 100% !important;
}

.form-group-container {
    margin-bottom: 25px;
}

.form-input {
    position: relative;
    margin: 10px;
    width: 200px;

    label {
        display: block;
        margin-bottom: 5px;
        font-size: 1.6rem;
    }
}

.form-input.full {
    width: 400px;

    @include respond-below(sm) {
        width: 90%;
    }
}

.form-button-panel {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
        border-radius: 5px;
        width: 175px;
        margin: 25px;
    }
}

.form-add {
    background-color: $accent-color;
    color: $light-text;

    svg {
        margin-right: 10px;
    }
}

.form-tooltip {
    position: absolute;
    top: -75px;
    width: 100%;
    left: 50%;
    box-shadow: $small-shadow;
    z-index: 100;
    color: white;
    background-color: $accent-color;
    font-size: 1.2rem;
    padding: 10px;
}

.form-input-icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;

    button {
        background-color: unset;
        color: $dark-blue;
        font-size: 2rem;
        box-shadow: unset;
    }
}

.form-submit {
    background-color: $brand-color;
}

.form-error-message {
    font-size: 1.4rem;
}


.DayPickerInput-OverlayWrapper {
    font-size: 1.6rem !important;
}