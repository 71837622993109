.app-heading {
  // margin-top: 50px;
  // text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  @include respond-below(sm) {
    flex-direction: column;
  }
  img {
    height: 75px;
    margin: 20px;

    @include respond-below(xs) {
      height: 50px;
      margin: 10px;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    color: $h3-color;
    margin-left: 25px;
    text-transform: uppercase;

    @include respond-below(md) {
      font-size: 2.3rem;
    }

    @include respond-below(sm) {
      font-size: 2.5rem;
    }
  }
}
.header-links {
  a {
    color: $dark-text;
    font-size: 1.8rem;
    margin: 25px;
    @include respond-below(md) {
      font-size: 1.5rem;
      margin: 15px 25px 15px 15px;
    }
  }

  @include respond-below(sm) {
    margin-bottom: 10px;
  }
  // margin-top: 20px;
  // a {
  //   text-decoration: none;
  //   color: #000;
  //   font-size: 1.3rem;
  //   margin: 10px;
  //   &:hover {
  //     color: #ccc;
  //   }
  // }
}
