.App {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-copy {
  margin: 0 10vw;

  a {
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    float: left;
    width: 50%;
    margin: 0px 20px 20px 0px;
    @include respond-below(sm) {
      width: 100%;
    }
  }
  @include respond-below(sm) {
    width: 90%;
  }
}
