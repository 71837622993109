.contact-container {
  display: flex;

  flex-direction: row;

  justify-content: space-between;
  max-width: 750px;
  width: 75vw;
  @include respond-below(sm) {
    flex-direction: column;
    align-items: center;
  }
}
.address-contact {
  display: flex;
  justify-content: flex-start;
  // width: 45%;

  flex-direction: column;
  @include respond-below(sm) {
    width: 100%;
    flex-direction: column-reverse;
    // justify-content: space-between;
  }
}
.detail-field {
  margin-top: 10px;
  h2 {
    margin-top: 0;
  }
  a {
    text-decoration: none;
  }
  p {
    margin-left: 1vw;
    margin-bottom: 4vh;
  }
  @include respond-below(sm) {
    margin-left: 0;
  }
}
.image-container {
  margin: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  a {
    display: flex;
  }
  img {
    object-fit: cover;
    width: 500px;
    @include respond-below(sm) {
      width: 97vw;
    }
  }
}
.App {
  h2 {
    margin-top: 0 !important;
  }
}
