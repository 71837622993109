.app-footer {
  margin-bottom: 50px;
  // text-align: center;
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: $dark-text;
  a {
    text-decoration: none;
    color: $dark-text;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
