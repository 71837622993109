.photos-view-container {
  padding-top: 0;
}

.app-container {
  margin-top: 0;
}

.photos-container {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  // width: 100vw;
  @include respond-below(sm) {
    flex-direction: column;
  }
  .hero-image {
    width: 100vw;
    height: 40vh;
    background: no-repeat center
      url("http://alexkostich.com/images/photography/IMG_6502.JPG");
    background-size: cover;
    margin-bottom: 10px;
    @include respond-below(sm) {
      background-position: 66% 0;
    }
  }
}
.ReactGridGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;

  @include respond-below(sm) {
    margin: 0;
  }
  iframe {
    position: unset !important;
  }
  div {
    transition-property: transform;
    transition-duration: 0.4s;

    .ReactGridGallery_tile-viewport {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
      @include respond-below(sm) {
        // margin: 0;
        height: 100% !important;
      }
      img {
        @include respond-below(sm) {
          // margin: 0;
          height: 100% !important;
          width: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        box-shadow: $box-shadow-cover-list;
        transform: scale(1.03);
        cursor: pointer;
        z-index: 1000;
      }
    }
  }
}
